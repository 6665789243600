html, body {
    height:100%;
}

body {
    padding: 0;
    margin: 0;
}

#header {
    width:100% !important;
    width:99%;
    z-index:0 !important;
    z-index:-2;
}

#content {
    /* padding: 12px 22px 12px 10px; */
    padding: 10px;
    position: relative;
    z-index:0 !important;
    z-index:1;
    margin-right:0 !important;
    margin-right:-17px;
    clear:both;
    width:98%;
    float:left;
}

#notification_area {
    border-radius: 10px;
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    display: inline-block;
}

/* Hides from IE-mac \*/
* html .clearfix {height: 1%;}
/* End hide from IE-mac */

.fake_inline_block {
    display: inline-block;
    /* thanks, IE, thanks a bunch */
    zoom: 1;
    *display: inline;
}

#track_header {
	background-image:url(/track/crs/images/main-nav-bg.jpg);
	background-repeat:repeat-x;
	padding-left:4px;
	padding-right:4px;
	float:left;
	height:42px;
	width:975px;
	z-index:10000 !important;
	font-size:10px;
	width:100%;
}

#track_header h1 a {
	/* -moz-background-clip:border;
	-moz-background-inline-policy:continuous;
	-moz-background-origin:padding; */
	background:transparent url(/track/crs/images/pythian-logo.png) no-repeat scroll 0 0;
	float:left;
	height:60px;
	margin-left:16px;
	text-indent:-9999px;
	width:160px;
}

/* Page Icon Set */

.page_icons {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/track/static/images/icon_map.png);
    background-repeat: no-repeat;
    vertical-align:middle;
    float:left;
    margin-right: 2px;
}

.add_icon {
    background-position: 0px 0px;
}

.save_icon {
    background-position: -16px 0px;
}

.form_edit_icon {
    background-position: -32px 0px;
}

.form_cancel_icon {
    background-position: -48px 0px;
}

.form_delete_icon {
    background-position: -80px 0px;
}

.form_copy_icon {
    background-position: -64px 0px;
}

.cancel_icon {
    background-position: -80px 0px;
}

.show_icon {
    background-position: 0px -16px;
}

.up_icon {
    background-position: -16px -16px;
}

.down_icon {
    background-position: -32px -16px;
}

.linkedin_icon {
    background-position: -48px -16px;
}

.global_add_icon {
    background-position: -64px -16px;
}

.link_icon {
    background-position: -48px -48px;
}


.expander_icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    vertical-align:middle;
    float:left;
    margin-right: 2px;
    cursor: pointer;
}

.expander_icon_expand {
    background-image: url(/track/static/images/toggle-expand-dark.png);
}

.expander_icon_collapse {
    background-image: url(/track/static/images/toggle-collapse-dark.png);
}


/* End Icon Set */

/* Contracts Summary (main index page) */

div.contracts {
    width: 880px;
    margin: 0px 0px 2em 0px;
}
div.contracts td,
div.contracts th {
    padding: 0.5em;
}

div.contracts th {
    background-color: #FFECEA;
}

table.contract_summary thead th {
    vertical-align: bottom;
}
table.contract_summary tr.allotment {
    display: none;
}
table.contract_summary .allotment_col { width: 200px }
table.contract_summary .team_col { width: 150px }
table.contract_summary .contracted_col,
table.contract_summary .worked_col,
table.contract_summary .min_col,
table.contract_summary .max_col,
table.contract_summary .cap_col,
table.contract_summary .bal_col {
    width: 3em;
    text-align: right;
}
table.contract_summary .team_lead {
    margin-left: 1em;
}

table.contract_summary td.toggle_detail {
    text-align: center;
}

table.contract_summary .contract_footer {
    background-color: #FFECEA;
    height: 5px !important;
}

/* End Contracts Summary (main index page) */

/* PY-3798 Fix an issue with text not wrapping properly in description and comments*/

.sidebar_block_fixed_width, .briefing_text {
    word-wrap: break-word;
}

.wordwrap {
    word-wrap: break-word;      /* Browsers are still required to support word-wrap */
    overflow-wrap: break-word;  /* W3 new alternate name for word-wrap */
}

.prewrap {
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    white-space: -webkit-pre-wrap;  /* Newer versions of Chrome/Safari */
    white-space: pre-wrap;      /* CSS3 */
}

.st_callout {
    padding:       0px 20px 20px 20px;
    border-style:  none none none solid;
    border-color:  rgb(136,29,19);
}

#track-mobile-menu {
    display: none;
}

#track-mobile-jump-to-cr {
    display: none;
}
