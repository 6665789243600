#support-track {
    line-height: normal;
}
#zUL,#zUL ul {padding:0;margin:0; margin-top:2px}
#zUL ul {margin-top:5px}
#zUL li ul a {margin-left:0; text-decoration:none;}
#zUL li {
    border: 3px;
    font-size: 12px; 
    font-family: arial, helvetica, sans-serif; 
    color:#5B4154;
    float:left;
    width:8em;
    position:relative;
    list-style-type:none;
}

#zUL a:hover, #zUL.msieFix {
    color:#FBFFFF;
}

.menu-title {
    background: url(/track/images/graphic.gif) no-repeat 0 0;
    height:34px;
    width:115px !important;
    padding-top:15px;
    padding-left:20px;
    margin-right:0px !important;
    margin-right:5px;
    display:block;
    font-weight:bold;
    
}


#track_header input {
    line-height: normal;
}

.menu-title.msieFix {
    background-position: -130px 0;
}

.menu-title a {
    text-decoration:none;
    display:block;
    margin-left:-20px;
    margin-top:-5px;
    padding-top:5px;
    padding-left:20px;
    height:19px !important;
    height:24px;
    color:#FBFFFF;
    text-transform:uppercase;
    font-size:100%;
    
    font-family:"Trebuchet MS";
    letter-spacing:1px;
}

.menu-title a:hover {
	text-decoration:none !important;
}

.menu-title:hover {
    background-position: -130px 0;
}

#zUL li ul {
    visibility:hidden;
    position:absolute;
    width:230px;
    font-size: 10px;
    border-top:4px solid #000;
    left:0px;
    z-index:1100;
}

#zUL li ul li{
    width: 230px;
    height: 17px;
    border-left:2px solid #6F696A;
    background-color:#6F696A;
    font-size: 12px !important;
    font-family: arial, helvetica, sans-serif; 
    color:#5B4154;
    z-index:1000;
    font-weight: bold;
}

#zUL li ul li a{
    width:230px;
    height:17px;
    margin-left:0;
    padding-left:2px;
    color:#C6C6C6;
    text-transform:none;
    letter-spacing:0px;
    font-size:11px;
}

#zUL li:hover ul,
#zUL li.msieFix ul {
    visibility:visible;
    background-color:#6F696A;
    z-index:9999;
}


/* This will allow for header color and link color customization
   it is intended to follow the theme of the main page.
*/

.track_table_header {
    background-color:#414141;
    color:#FFFFFF;
}

a.track_table_header {
    color:#000000;
    text-decoration:none;
    font-weight:bold;
}

font.track_cr_header {
    color:#FFFFFF;
}

.track_cr_header {
 	font-weight:bold;
}



.track_cr_grouping_header div {
    width: 100%;
}

a.track_cr_grouping_header {
    color:#FFFFFF;
    text-decoration:none;
}

a.track_cr_grouping_header:hover {
    text-decoration:underline;
}

a.track_table_header:hover {
    text-decoration:underline;
}

a.page_link {
    text-decoration:underline;
    font-color: #000000;
}

a.page_link:hover {
    text-decoration:none;
}

a.light_page_link {
    text-decoration:none;   
    color:#FFFFFF;
    font-weight:bold;
}

a.light_page_link:hover{
    text-decoration:underline;
}

.track_subtable_header {
    background-color:#C9C9C9;
    color:#FFFFFF;
    font-weight:bold;
}

.text_emphasis {
    color:red;
    font-weight:bold;
}

.numeric_emphasis {
   color:#BB9090;
   font-weight:bold;
   font-style:italic;
}

.cr_entry {
	font-style:italic;
}

.page_title {
    font-size:16px;
    font-weight:bold;
    color:#444444;
}

.track_grouping_header {
    font-size:14px;
    font-weight:bold;
    color:#FFFFFF;
    background-color:#901212;
}

.float_right {
    text-align:right;
    width:48%;
    display:inline-block;
}

.float_left {
    text-align:left;
    width:50%;
    display:inline-block;
}

.track_cr_grouping_header {
    font-size:12px;
    font-weight:bold;
    color:#FFFFFF;
    background-color:#901212;
}

.track_cr_grouping_header_invert {
    font-size:12px;
    font-weight:bold;
    background-color:#FFFFFF;
    color:#901212;
}

th.track_theme_text, .track_caption_text {
    font-size:14px;
    font-weight:bold;
    color:#444444;
}

.track_header_caption_text {
    font-size:14px;
    font-weight:bold;
    color:#FBFFFF;
}

td.track_cell_warn {
    background-color:#CC0000;
    color:#FFFFFF;
}

p.track_theme_text {
    font-size:12px;
}

tr.track_row {
    background-color:#FFFFFF;
}

tr.row_alternate_even, td.row_alternate_even {
    background-color:#FFFFFF;
    color:#000000;
}

tr.row_alternate_odd, td.row_alternate_odd {
    background-color:#E7E7E7;
    color:#00000;
}

tr.row_alternate_cur {
    background-color:#99D1FF;
    color:#000000;
}

tr.row_not_owned {
    color:#868686;
}

tr.track_table_footer, td.track_table_footer {
    background-color:#000000;
    color:#FFFFFF;
    font-weight:bold;
}

body {
    background-color:#000000;
}

.logout_link {
    text-decoration:underline;
}

.support_track_title {
    font-size:14px;
    font-weight:bold;
}

#tbl_frame {
    width: 100%;
}

.table_white_cell {
    background-color:#FFFFFF;
}

.table_current_cell {
    background-color:#E7E7E7;
}

.table_unique_cell {
    background-color:darkblue;
}

.table_warn_cell {
    background-color:#901212;
}

.track_cr_header {
    background-color:#414141;
    color:#FFFFFF;
}

.table_important_cell {
    /* Must change this */
    background-color:#99CCFF;
}

.notifications {
    background-color:red;
    margin-top: 5px;
    padding:2px;
    min-width:150px;
    text-align:center;
    width:auto !important;
    width:150px;
}

.track_info_area {
    text-align:right !important;
    margin-right:5px;
}

.track_header_font, .track_general_font {
    color:#FFFFFF;
}

form.track_skills_form , form.track_micro_form {
    display:inline;
}

.track_header_invert {
    width:99%; 
    text-align:right;
}

.post_frame {
    border:1px solid #000;
    margin:1px;
    /*width:100%;*/
}

.small_text {
    font-size:10px;
}

.track_info {
    padding-top:15px;
    margin-right:5px;
}

a.track_link {
    color: #000000;
    text-decoration: underline;
    font-size:inherit;
}

a.track_link:hover {
    text-decoration: none;
}

.unique_txt_color {
    color:#901212 !important;
}

#track_menu {
    position: absolute;
    width: 650px;
    height: 32px;
    top:31px;
    left:220px;
}

.sidebar_block_fixed_width {
    width:745px;
    overflow-x:auto !important;
    overflow-x:scroll;
    padding:1px;
}

 .menuitem {border: 3px;
            font-size: 12px; 
            font-family: arial, helvetica, sans-serif; 
            color:#5B4154;
            position:absolute;
            z-index:2000; 
            font-weight: bold}
 .listitem {width: 140px; 
            height: 17px; 
            border: 2px solid #F1B446; 
            background-color: #F1B446;
            font-size: 12px; font-family: arial, helvetica, sans-serif; 
            color:#5B4154;
            position:absolute; 
            z-index:1000; 
            font-weight: bold; 
            visibility: hidden}
      .clear {position:absolute;
              z-index:10000}
      .clear2 {position:absolute;
               z-index:10000;
               visibility: hidden}
      .graphic {position:absolute;
                z-index:1400}

      .logtext {font-family: "Courier New",Courier,monospace;
                font-size: 12px}
      .smallwhite {font-family: arial, helvetica, sans-serif;
                   font-size: 12px; 
                   color: white}
      .collapsible_toggle, .toggle_row_entry {
                font-family:arial,helvetica,sans-serif;
                text-decoration:none;
                font-size:1.15em;
                font-weight:bold;
                color:#FFFFFF;
    }
    .collapsible_toggle:hover {
    	text-decoration: none !important;
    }
 .digit {
 	font-family: arial, sans-serif;
           font-size: 14px;
               color: white;
    background-color: #000000;
               width: 9px;
              height: 13px;
}

body {
	margin: 0px 1px;
	font-size: 12px;
	font-family: arial, helvetica, sans-serif;
}
      table {font-size: 12px; font-family: arial, helvetica, sans-serif; color:#444444}
      select {z-index:999} 
      tr { vertical-align: top;} 
      .cr_text  {width: 65em; 
          white-space: pre-wrap;       /* css-3 */
      }
      .cell_table_right{ white-space: nowrap;
                  text-align: right;}
      .cell_table_left{ white-space: nowrap;
                  text-align: left;}
     .cell_table_centre{ white-space: nowrap;
                  text-align: center; }
     .cell_right{ white-space: nowrap;
                  text-align: right;
                  width: 5%;
                  max-width: 5%; } 
     .cell_left{ white-space: nowrap;
                  vertical-align: top;
                  text-align: left;
                  width: 95%;
                  max-width: 95%; }
     .cell_right_3{ white-space: nowrap;
                  vertical-align: top;
                  text-align: right;
                  width: 1%;
                  max-width: 1%; }
     .cell_left_3{ white-space: nowrap;
                  vertical-align: top;
                  text-align: left;
                  width: 30%;
                  max-width: 30%;}

    .cell_edit{white-space: nowrap;
                vertical-align: top;
                text-align: center;
                width: 1%;}
      .cell_centre{ white-space: nowrap;
                  vertical-align: top;
                  text-align: center;
                  width: 1%;}

   .smalltextwhite{font-size: 10px;
              font-family: arial, helvetica, sans-serif;
              color: white;
              font-weight: bold;}
      .smalltext{font-size: 10px;
              font-family: arial, helvetica, sans-serif;}
       .basicgrey{font-size: 12px;
              color: #9999CC;}
      .boldbasicwhite{font-size: 12px;
              color: white;
              font-family: arial, helvetica, sans-serif;
              font-weight: bold;}

.basic {
    font-size: 12px; 
    font-family: arial, helvetica, sans-serif;
    font-weight: bold;
}

.basic tr th a:hover {
    text-decoration:underline;
}

      .titletext {font-size: 16px; 
          font-family: arial, helvetica, 
                  sans-serif; 
                  color:#444444; 
                  font-weight: bold}
      .tinytext {font-size: 1px}
      .bartext {font-size: 12px; 
        font-family: arial, helvetica,  sans-serif; 
        color:#FFFFFF}
      .boldbartext {font-size: 12px;
                font-family: arial, helvetica,  sans-serif;
                color:#FFFFFF;
                font-weight: bold}
      .bigbartext {
        font-size: 14px;
        font-family: arial, helvetica, sans-serif; 
        color:#FFFFFF; 
        font-weight: bold}
      .helpdiv {
        text-align: left;
        text-transform: none;
        position: absolute;
        z-index: 3;
        border: 1px outset #322;
        background-color: #FFE;
                padding: 6px;
        width: 350px;
        font-weight: normal;
        font-size: 10px;
                font-family: arial, helvetica, sans-serif;
        color: #000000;
    }

.td-bar-bot {
    background: url(/track/images/barbot.gif) repeat 0 0;
	height: 5px;
}
.td-bar-bot-left {
    background: url(/track/images/barbotleft.gif) no-repeat 0 0;
}
.td-bar-bot-right {
    background: url(/track/images/barbotright.gif) no-repeat 0 0;
}
.td-bar-top {
    background: url(/track/images/bartop.gif) no-repeat 0 0;
	height: 5px;
}
.td-bar-top-left {
    background: url(/track/images/bartopleft.gif) no-repeat 0 0;
}
.td-bar-top-right {
    background: url(/track/images/bartopright.gif) no-repeat 0 0;
}
.td-bar-left {
    background: url(/track/images/barleft.gif) no-repeat 0 0;
}
.td-bar-right {
    background: url(/track/images/barright.gif) no-repeat 0 0;
}

/* PY-4440: Specific styling for TWiki results */

div.search_item.wiki > ul {
    margin-top: 0;
    margin-bottom: 2px;
}

div.search_item.wiki > div {
    display: table-cell;
    padding-top: 0.5em;
}

/* PY-4187: Styling for search hints */

.advanced_options, .search_hints {
    border: 1px solid;
    padding: 5px;
    display: none;
}

table.examples td {
    vertical-align: middle;
    text-align: center;
    padding: 4px;
}

table.examples tr.odd {
    background-color: #ebebeb;
}

#save_error {
    padding:5px;
    border: 1px solid #FF0000;
    background: #FFA8A8;
    text-align:left;
    margin-top: 10px;
    margin-bottom: 10px;
}

#save_success {
    padding:5px;
    border: 1px solid #00FF00;
    background: #A8FFA8;
    text-align:left;
    margin-top: 10px;
    margin-bottom: 10px;
}
/* PY-4452: Styling to bring focus to URL of pythian.com results */

div.search_item.web > div.search_doc_url {
    text-decoration: underline;
    margin-top: 2px;
}

#live_view_area {
    background: none repeat scroll 0 0 #000000;
    border-radius: 5px;
    float: right;
    height: 27px;
    margin-top: 4px;
    padding: 0 10px 0 5px;
    display: none;

}

#live_view_session {
    float: right;
    margin-top: 6px;
}

.live_session {
    font-weight:bold;
    margin-right:15px;
    margin-left:15px;
    text-transform:uppercase;
    letter-spacing:1px;
}

.session_archive {
    font-weight:bold;
    text-transform:uppercase;
    letter-spacing:1px;

}
.session_font {
   font-family:"Trebuchet MS";
}

#adminiscope_logo {
   float:right;
   height:35px;
}

.adminiscope_logo_style {
   margin-top:0px;
   vertical-align:middle;
}

.modal-title {
    color: black;
}
