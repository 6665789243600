/*Pythian modifications */
.ui-datepicker select {
    color: #333;
}
.ui-widget-header input,
.ui-widget-header select,
.ui-widget-header button {
    color: #333;
}

.ui-autocomplete.ui-front {
    z-index: 10000000;
}
