/* for jquery combobox */
.custom-combobox {
    position: relative;
    display: inline-block;
}
.custom-combobox-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-left: -1px;
    padding: 0;
    /* support: IE7 */
    *height: 1.7em;
    *top: 0.1em;
    bottom: 0;
    margin-left: -1px;
    height: 25px !important;
}
.custom-combobox-input {
    margin-left: 5px;
    height: 22px !important;
    padding-left: 8px !important;
}



