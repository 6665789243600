/* -- General Layout Design -- */
body {
    background-color: #FFF;
    font-family: Verdana, Helvetica, Arial;
}

.innerWrapper {
    background: #fff;
}
#header {
    background: #000 url(/track/crs/images/header-bg.jpg) repeat-x 0 0;
    min-width:1015px !important;
    z-index:9000 !important;
    overflow-x:auto;
    overflow-y:hidden;
}

#skilltag_table {
	margin-bottom:5px;
}

.header_track {
    /* New */
    position:absolute; /* DEMO */
    overflow:auto;
    top:40px;
    z-index:9000 !important;
    height:50px;
}

.login {
	width:1002px !important;
}

#globalNav {
    width: 600px;
    float: right;
    margin-top: 15px;
}
#breadcrumb{
    background: url(/track/crs/images/content-gradient-top.gif) repeat-x 0 0;
}
#footer {
    padding: 16px 0;
    background: #000 url(/track/crs/images/footer-bg.gif) repeat-x 0 0;
}
.full-width .innerWrapper {
    margin-left: 6px;
    margin-right: 6px; /* was 14 */
}
.full-width #content {
    padding-right:24px; /* Fix for content looking like its overflowing, if it is overflowing fix STC */
    padding-top: 14px;
}
.searchResultsWrapper #content{
    width: 925px;
}
#home-page #content {
    padding-right: 10px;
}
#home-page .innerWrapper {
    padding-bottom: 10px !important;
}
.full-width #leftSidebar {
    display: none;
}
.no-sidebar #content {
    /*width: 752px;*/
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.no-callouts {
    padding-top: 6px; /* was 16 */
    z-index:0 !important;
    height:100%;
    position:relative; /* Added */
}

.no-callouts #rightSidebar, #support-track #breadcrumb{
    display: none;
}

/* -- Common text styles -- */
h1 {
    font-size: 131%;
    color: #666;
    font-weight: bold;
    margin-bottom: 3px;
    font-family: Verdana;
}
h2{
    font-size: 108%;
    font-weight: bold;
    margin: 16px 0 0 0;
    font-family: "Trebuchet MS";
}
h3{
    font-size: 100%;
    color: #000;
    font-weight: bold;
    font-family: Verdana;
    line-height: 131%;
}
h4{
    font-size: 93%;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 131%;
}
h5{
    font-size: 93%;
    color: #FFFFFF;
    font-weight: normal;
}
h6{
    font-size: 93%;
    color: #FFFFFF;
    font-weight: normal;
    font-style: italic;
}
p{
    font-size: 85%;
    color: #333;
    margin: 0 0 10px 0;
    line-height:131%;
    font-family: Verdana;
}
p em{
    font-style: italic !important;
}
a {
    text-decoration: underline;
    color: #000;
    outline: none !important;
}
strong {
    font-weight: bold;
}
.bold {
    font-weight: bold;
}
blockquote {
    padding: 0 26px;
    font-style: italic;
    font-size: 100%;
    color: #666;
}
span.redText {
    color: #901212;
}
#globalNav a {
    color: #F8E344;
}
#secondaryNav, #secondaryNav a {
    color: #071CBB;
}
a:link, a:visited {}
a:hover {
    text-decoration: underline;
}
#content p a {
    font-size: inherit;
    color: #000;
    text-decoration: underline;
}
#content p a:hover {
    text-decoration: none;
}
#content h2{
    color: #000;
    /*padding-left: 3px;*/
    padding-right: 3px;
    line-height: 123.1%;
}
#content h2 a{
    line-height: 123.1%;
}
#content h3{
    margin-top: 16px;
    padding-bottom: 0;
    /*padding-left: 3px;*/
    padding-right: 3px;
}
div.innerBanner + h3, #content h3 + h4, .box-content h2 + p{
    margin-top: 0 !important;
}
.callout h3 +p, #content h3 + ul{
    margin-top: 10px !important;
}
#content h4{
    color: #414141;
    /*padding-left: 3px;*/
    padding-right: 3px;
    margin: 16px 0;
}
#rightSidebar h2{
    color: #414141;
    text-transform: uppercase;
}
#rightSidebar .callout h3{
    font-weight: normal;
    color: #fff;
    font-family: "Trebuchet MS";
}
#content p{
    margin-top: 10px;
    /*padding-left: 3px;*/
    padding-right: 3px;
}
#content p a{
    line-height: inherit;
}
#content .largeText{
    font-size:121%;
}
#content .largerText{
    font-size:161%;
}
#content .smallText{
    font-size: 77%;
}

/* Misc. custom elements */
a.nav-arrows{
    width: 24px;
    height: 24px;
    display: block;
    background: url(/track/crs/images/arrows-all.gif) no-repeat 0 0;
    text-indent: -9999px;
}
a.nav-arrows.previous{
    width: 24px;
    height: 24px;
    display: block;
    background: url(/track/crs/images/arrows-all.gif) no-repeat 0 0;
}
a.nav-arrows.next{
    background-position: -24px 0;
}
a.nav-arrows.next:hover{
    background-position: -24px -24px;
}
a.nav-arrows.previous:hover{
    background-position: 0 -24px;
}
.three-columns-floated div{
    width: 33%;
    float: left;
}
.two-columns-floated div{
    width: 50%;
    float: left;
}
.trackrow{
    width: 100%;
    margin: 10px 0 0 0;
    position: relative;
}
.services-landing-page .trackrow{
    margin: 0;
}
.trackrow .cell{
    width: 210px;
    float: left;
    overflow: visible !important;
    margin-right: 10px;
    padding: 0 16px 0 24px;
    position: relative;
}
.trackrow .last{
    margin-right: 0;
}
#tabWrapper .trackrow .cell {
    width: 308px;
}
.full-width .trackrow .cell{
    width: 299px;
}
.solutions-landing-page .trackrow {
    width: 685px;
    padding: 0 0 0 19px;
}
.solutions-landing-page .trackrow .cell {
    width: 215px !important;
    padding: 0;
    overflow: visible;
}
.trackrow .cell h2{
    margin-top: 10px;
}
.box{
    width: 178px;
    background-color: #ebebeb;
    margin: 0 10px 0 0;
}
.full-width .trackrow .box{
    width: 348px;
}
.widest{
    width: 100% !important;
}
.widest .box{
    width: 668px !important;
}
.box .box-content{
    padding: 16px;
    float: left;
}
#content a.viewMore{
    color: #333;
    font-size: 85%;
    font-family: "Trebuchet MS";
}
.box .box-content ul{
    margin-bottom: 16px;
}
.box .box-content ul.no-bullets{
    margin-left: 0 !important;
}
.dottedBorder{
    border: 1px dotted #999;
    margin: 20px 0;
    padding: 16px 0 16px 0;
}
h2.dottedBorder, h3.dottedBorder, h4.dottedBorder{
    margin: 10px 0 0 0;
    padding: 10px 0 0 0;
}
#content h1 + h2.dottedBorder{
    margin-top: 10px !important;
}
#content div.dottedBorder{
    margin-top: 26px !important;
    padding: 10px 0 0 0;
}
.tb{
    border-width: 1px 0 !important;
}
.b{
    border-width: 0 0 1px 0 !important;
}
.t{
    border-width: 1px 0 0 0 !important;
}

.content-footer{
    width: 100%;
    height: 30px;
    background-color: #e7e7e7;
    clear: both;
    position: absolute;
    bottom: 0;
    left: 0;
}
#content .content-footer.relative{
    margin-top: 26px !important;
}
.tabContent .content .content-footer{
    left: 16px;
}

.contentPagination a{
    width: 111px;
    height: 30px;
    display: block;
    float: left;
    text-indent: -9999px;
    border: solid #fff;
    background: url(/track/crs/images/next-and-previous.gif) no-repeat;
}
.contentPagination a.next{
    float: right;
    background-position: -111px 0;
    border-width: 0 0 0 2px;
}
.contentPagination a.next:hover{
    background-position: -111px -30px;
}
.contentPagination a.previous{
    background-position: 0 0;
    border-width: 0 2px 0 0;
}
.contentPagination a.previous:hover{
    background-position: 0 -30px;
}

.content-footer a.getStarted{
    width: 111px;
    height: 30px;
    display: block;
    float: left;
    text-indent: -9999px;
    border-right: 2px solid #fff;
    background: url(/track/crs/images/get-started-btn.gif) no-repeat;
}
.content-footer a.getStarted:hover{
    background-position: 0 -30px;
}
.content-footer a.readMore{
    width: 111px;
    height: 30px;
    display: block;
    float: left;
    text-indent: -9999px;
    border-right: 2px solid #fff;
    background: url(/track/crs/images/read-more-btn.gif) no-repeat;
}
.content-footer a.last{
    border-right: none;
}
.content-footer a.readMore:hover{
    background-position: 0 -30px;
}

.solutions-landing-page .content-footer a.getStarted, .solutions-landing-page .content-footer a.readMore{
    width: 106px !important;
}
.content-footer a.getStarted:hover{
    background-position: 0 -30px;
}

a.modalCloseImg{
    text-indent: -9999px;
    background-color: transparent !important;
    background-position: 0 0;
}
a.modalCloseImg:hover{
    background-position: 0 -29px;
}

/* Header styles */
#header h1 {
    width: 160px;
    height: 55px;
    float: left;
    position: relative;
    /*top: -10px !important;
    top: 0;*/
    margin-bottom: 0px;
    margin-top:    0px;
}

#header span a{
    width: 160px;
    height: 50px;
    margin-left: 16px;
    text-indent: -9999px;
    float: left;
    background: url(/track/crs/images/pythian-logo-small.png) no-repeat 0 0;
    margin-top: 5px;
}

#header h1 a{
    width: 160px;
    height: 60px;
    margin-left: 16px;
    text-indent: -9999px;
    float: left;
    background: url(/track/crs/images/pythian-logo.png) no-repeat 0 0;
    margin-top:-5px;
}

div#send-love a{
    width: 60px;
    height: 60px;
    margin-left: 10px;
    text-indent: -9999px;
    float: left;
    background:transparent url(/track/crs/images/small_heart_only.gif) no-repeat 0 0;
    z-index:9000 !important;
    margin-top: 3px;
}

#header h1 a span{
    text-indent: -9999px;
}
/* Main content styles */
.innerBanner {
    width: 511px;
    margin: 16px 0 10px 0;
}
/* Section-specific styles */

/* Contact page styles */

#locationNav {
    width: 187px;
    margin: 0 0 20px 13px;
    padding: 16px 0;
    overflow:hidden;
    background-color: #fff;
}
#locationNav .locationsIntro {
    width: 151px;
    margin: 0 0 10px 0;
    padding: 8px;
    float:left;
    overflow:hidden;
    background-color: #f0f0f0;
}
#locationNav h3 {
    width: 151px;
    float:left;
    margin-left: 16px;
}
#locationNav p {
    width: 151px;
    float:left;
    color: #333;
    padding: 0 16px 8px 16px;
}

#locationNav .locationHeader {
    width:167px;
    float:left;
    margin: 0 0 8px 10px !important;
}

#locationNav .locationHeader a {
    width:155px;
    display:block;
    padding: 4px 4px 4px 8px;
    color: #333333;
    border: 1px solid #000;
    background:#ebebeb;
    line-height: 121%;
}

#locationNav .locationHeader a:hover {
    color: #901212;
    text-decoration: none;
    background-color: #e7e7e7;
    border: 1px solid #666;
}

#locationNav .locationHeader a.current {
    padding: 4px 4px 4px 8px;
    color: #fff;
    text-decoration: none;
    background-color: #901212;
    border: 1px solid #901212 !important;
}

#locationNav .locationHeader a.current:hover {
    background: #901212;
    color:#fff;
}

#locationNav div.clearfix {
    width: 180px;
    float:left;
    padding: 0;
    display:none;
}

#locationNav h4 {
    font-weight:bold;
    margin-bottom: 5px;
}

#locationNav .thumbnail { width: 167px;
    float:left;
}

#locationNav .thumbnail a { float:left;
    margin:8px 12px 12px 16px;
    border:1px solid #a0a0a0;
    padding: 2px;
}

#locationNav .thumbnail a:hover {
    border-color: #ccc;
}

#locationNav img {
    float:left;
}

#locationNav .adr {
    float: left;
    width: 164px;
    padding:8px 0 0 16px;
    margin-left: 0;
}

#locationNav .adr p {
    padding-left: 0;
    padding-right: 0;
}

/* Tabs styles */
#tabWrapper {
    width: 706px;
    clear: both;
    margin: 16px 0;
    position: relative;
}
#tabWrapper ul.tabs {
    width: 100%;
    float:left;
    position: relative;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}
#tabWrapper ul.tabs li{
    float:left;
    margin-right: 8px !important;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
}
#tabWrapper ul.tabs li a{
    width: 110px;
    min-height: 44px;
    display: block;
    padding: 0;
    color: #414141;
    text-decoration: none;
    white-space: normal;
    background-color: #c5c5c5;
    float:left;
    position: relative;
}
#tabWrapper ul.tabs li a:hover{
    background-color: #8b8a8a;
    text-decoration: none;
}
#tabWrapper ul.tabs li a.current, #tabWrapper ul.tabs li a:active{
    color: #fff;
    background-color: #8f1b1b;
}
#tabWrapper ul.tabs li a span{
    display: block;
    width: 107px;
    padding: 0 3px 0 0;
    position:absolute;
    bottom: 8px;
    left: 8px;
    line-height: 108%;
}
#tabWrapper .tabContent{
    width: 100%;
    min-height: 200px;
    float: left;
    background: url(/track/crs/images/tab-gradient-bg.gif) repeat-x 0 0;
    position: relative;
}
#tabWrapper .tabContent .content{
    float: left;
    padding: 0 16px 60px 16px;
    margin-top: 26px;
    position: relative;
}
#tabWrapper .innerBanner{
    width: 100%;
    margin-bottom: 16px;
    background-color: transparent;
}
#tabWrapper .tabContent h3{
    margin-top: 26px;
}
#tabWrapper .tabContent .content p{
    line-height: 1.2;
}
/* Expertise landing page */
.expertise-landing-page{
    position: relative;
}

/* Customers page */
#customers {
    margin: 10px 0;
}
#customers h3{
    border-bottom: 1px dotted #b0b0b2;
    margin-top: 20px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;
}
#customers table td, #partners table td{
    padding: 12px;
    vertical-align: middle;
    text-align: center;
}
/* Partners page */
#partners {
    margin-top: 20px;
}
#partners h2{
    border-bottom: 1px dotted #b0b0b2;
    margin-top: 20px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    color: #000;
    font-weight: bold;
}
#partners .partner{
    width: 230px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    /*border-bottom: 1px solid #e7e7e7;*/
    float: left;
    margin-right: 16px;
}
#partners .last{
    margin-bottom: 26px;
    border-bottom: none;
}
/* Solutions page */
.solutions-tabs h2 {
    font-size: 138.5%;
    font-weight: normal;
    color: #333 !important;
    margin-top: 0 !important;
}
.solutions-tabs .content {
    margin-top: 16px !important;
}

/* Resource Library page */
.library-landing-page .box-content h2 {
    width: 300px;
    height: 40px;
    color: #000 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
}

.news-landing-page .box-content h2 {
    height: 40px;
    color: #000 !important;
    margin-bottom: 10px;
    margin-top: 0 !important;
}

.library-landing-page .box-content h3 {
    margin-bottom: 3px;
}
.library-landing-page .box-content {
    padding-bottom: 26px;
}
.library-landing-page .box-content p, .library-landing-page .box-content h3, .library-landing-page .box-content ul {
    margin-right: 8px !important;
    margin-left: 8px !important;
}
.library-landing-page .box-content h2 a {
    display: block;
    width: 260px;
    height: 32px;
    padding-left: 40px;
    padding-top: 8px;
    font-size: 146.5%;
    color: #000 !important;
    background-repeat: no-repeat !important;
}

.news-landing-page .box-content h2 a {
    display: block;
    height: 32px;
    padding-left: 40px;
    padding-top: 8px;
    font-size: 146.5%;
    color: #000 !important;
    background-repeat: no-repeat !important;
}

.library-landing-page h2.webinars a, .news-landing-page h2.webinars a {
    background: url(/track/crs/images/icon-webinars.gif) 0 0;
}
.library-landing-page h2.scripts a {
    background: url(/track/crs/images/icon-scripts.gif) 0 0;
}
.library-landing-page h2.papers-and-books a {
    background: url(/track/crs/images/icon-papers-and-books.gif) 0 0;
}
.library-landing-page h2.sign-up {
    background: url(/track/crs/images/icon-sign-up.gif) 3px 0 no-repeat;
    width: 244px !important;
    height: 32px;
    padding-left: 43px !important;
    padding-top: 8px;
    margin-bottom: 0 !important;
    font-size: 146.5%;
}
#container .news-landing-page .viewMore {
    padding-right:10px;
}
#container .news-landing-page .content-footer {
    padding:10px 0 10px 10px !important;
    width:248px;
    height:auto;
    background:none;
    position:relative;
}

#container .news-landing-page .box-content p {

}

#newsSidebarContact {
    padding:10px 0 0 30px;
    clear:both;
}

#leftSidebar #newsSidebarContact h2.media-contact {
    background:none;
    padding:5px 0 5px 0;
}

.library-landing-page h2.blog a {
    background: url(/track/crs/images/icon-blog.gif) 0 0;
}
h2 + .smallText{
    margin-top: 0 !important;
    color: #333;
}
h3 + .smallText{
    margin-top: 0 !important;
    color: #333;
}
.library-landing-page .box-content ul{
    margin-bottom: 20px !important;
}
.library-landing-page .box-content .content-footer{
    width: 326px !important;
    height: 26px !important;
    margin-bottom: 0 !important;
    background-color: transparent !important;
}
.library-landing-page .widest .box-content .content-footer{
    width: 690px !important;
}
.library-landing-page .widest .box-content {
    padding-bottom: 30px !important;
}
.library-landing-page .box-content .content-footer a.viewMore{
    font-weight: bold;
}
.library-landing-page .box-content ul.blogCategories{
    width: 480px;
    height: 20px;
    position: absolute;
    top: 16px;
    right: 16px;
    text-align: right;
}
.library-landing-page .box-content ul.blogCategories li, .news-landing-page .box-content ul.blogCategories li{
    height: 12px;
    display: inline;
    border-right: 1px solid #666;
    padding: 0 4px;
}
.library-landing-page .box-content ul.blogCategories li.last, .news-landing-page .box-content ul.blogCategories li.last{
    border-right: none !important;
}
.library-landing-page .box-content ul.blogCategories li a, .news-landing-page .box-content ul.blogCategories li a{
    color: #333 !important;
    font-weight: bold;
}
.library-landing-page .box-content ul.blogCategories li a:hover, .news-landing-page .box-content ul.blogCategories li a:hover{
    color: #990000 !important;
    text-decoration: none;
}

/* Webinars page */
.content-footer a.notify {
    display: block;
    width: 198px !important;
    height: 30px;
    float: left;
    text-indent: -9999px;
    background: url(/track/crs/images/notify.gif) no-repeat;
}
.content-footer a.notify:hover {
    background-position: 0 -30px;
    background-color: #e1e1e1 !important;
}


/* Client testimonials page */
.clientTestimonials blockquote {
    margin-bottom: 0;
    padding-bottom: 0;
}
.clientTestimonials .trackrow {
    padding-top: 0 !important;
    margin-top: 6px !important;
}
.clientTestimonials .trackrow .right, .clientTestimonials .trackrow blockquote {
    padding-right: 10px;
    padding-left: 10px;
}
.clientTestimonials .trackrow .right h2 {
    margin-top: 8px;
}
.clientTestimonials .trackrow .right h2, .clientTestimonials .trackrow .right p {
    margin-bottom: 0;
    text-align: right;
}
.clientTestimonials .trackrow span.right {
    padding-right: 0;
}
/*
.news-landing-page .box { width:49%; overflow:hidden; }*/

.eventList {
    border-top:1px dotted #000;
}

.eventWrapper {
    padding: 0 16px 10px 25px !important;
}

.releaseWrapper {
    padding: 10px 0;
    border-bottom:1px dotted #000;
}
.eventWrapper h2 {
    background:url(/track/crs/images/eventWrapper.gif) no-repeat 0 0;
    padding: 6px 0 6px 30px;
}

.eventShare, .eventShare p {
    text-align:right !important;
}

.releaseShare {
    border-bottom:1px dotted #000;
    text-align:right;
    margin-top:-25px;
}

.releaseShare p {
    text-align:right;
}

.eventShare a.addthis_button, .releaseShare a.addthis_button {
    color:#990000;
    background:url(/track/crs/images/share.gif) no-repeat top left;
    padding:2px 0 2px 23px;
}

.releaseReadMore {
    color:#990000 !important;
}
#rightSidebar.eventSidebar, #rightSidebar.releaseSidebar {
    padding-top:12px;
}

/* news section */

#container .eventBox h2 {
    height:auto;
    margin:0;
}

#container .eventBox {
    padding-bottom:20px;
}

#container .eventBox p {
    margin:0px;
}

#container .eventBox a {
    color:#990000;
}

#container .box-content .newsIndexContainer h2, #container .box-content .newsIndexContainer h2 a {
    font-weight:bold; margin:0; height:auto !important; padding:3px 1px !important;
}

#container .box-content .newsIndexContainer p a {
    color:#990000;
    text-decoration:none;
}

#container .box-content .newsIndexContainer p a:hover {
    color:#000;
    text-decoration:underline;
}

#container .news-landing-page .box-content {
    padding-top:10px !important;
    padding-bottom:10px !important;
    overflow: hidden;
}

#container .news-landing-page .box.left {
    width:290px;
}

#container .news-landing-page .box.last {
    width:210px;
}

#container .news-landing-page .box.last .box-content {
    padding-left:5px;
    padding-right:0;
    width:205px;
}

span.byline {
    font-size:0.8em;
    padding:2px 0;
    line-height:1.4em;
}

p.byline {
    color:#999999;
}

.inTheNews .article {
    padding:10px 0;
    border-bottom:1px dotted #000;
}
#rightSidebar .tags {
    display:none;
}

#content h2 + ul {
    padding-top:12px;
}



#content .newsIndexContainer h2, #content .newsIndexContainer h2 a { font-size:13px; float:none; margin:3px 0px; padding:0px; line-height:14px; }

h2.pythian-articles { background:url(/track/crs/images/icon-articles.gif) no-repeat 0px 5px; }

h2.pythian-events { background:url(/track/crs/images/icon-events.gif) no-repeat 0px 5px; }

h2.media-contact { background:url(/track/crs/images/icon-media.gif) no-repeat 0px 5px; }

.noBG { background:none !important; }

#rightSidebar a.testimonialsMore { color:#990000; padding-top:10px; display:block; float:right; }

.homePanePanel { width:45%; padding-right:5%; float:left; font-size:0.8em; }

span.red { color:#990000; }

/* DBA resourcing */
#content .dbalegend ul
{
    margin: 0px;
}

#content .dbalegend li
{
    font-size: inherit;
    line-height: inherit;
    margin: 0px;
}
